<template>
    <v-app-bar app elevation="1">
        <v-app-bar-nav-icon @click="toggleDrawer(!drawer)" />

        <v-spacer />

        <span class="mr-2"
            >Hello <strong>{{ user.first_name }}</strong
            >!</span
        >

        <!-- Avatar -->
        <customMenu icon :links="links"> </customMenu>
    </v-app-bar>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
    components: {
        customMenu: () => import("@/components/inputs/customMenu"),
    },
    computed: {
        ...mapState(["drawer"]),
        ...mapState("userSelfMgmt", ["user"]),
    },
    data: () => ({
        links: [
            { icon: "mdi-account", route: "Profile", name: "Profile" },
            { icon: "mdi-cog-outline", route: "Admin", name: "Admin" },
        ],
        profile: {
            firstName: "James",
            lastName: "Bloom",
            subtitle: "",
        },
    }),
    methods: {
        ...mapMutations({ toggleDrawer: "TOGGLE_DRAWER" }),
        logout(email) {
            this.$store.dispatch("authsMgmt/logoutAction", {
                email: email,
            });
        },
    },
    name: "HeaderNav",
    // Lifecycle hooks.
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
};
</script>

<style scoped>
.header-logo {
    color: black;
    text-decoration: none;
}
</style>
